import WorkoutRow from "./WorkoutRow/WorkoutRow";
import { Table, Spinner } from 'react-bootstrap';


const WorkoutTable = ({ daysOfWeek, workouts, workoutTypes, workoutSubtypes, updateWorkout, deleteWorkout, createWorkout, loadingWorkouts }) => {
    return (
        <>
        {loadingWorkouts ? (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        ) : (
        <Table responsive bordered>
            <thead>
                <tr>
                    <th style={{ width: '5%' }}>Date</th>
                    <th style={{ width: '5%'}}>Workout type</th>
                    <th style={{ width: '30%' }}>Assignment</th>
                    <th style={{ width: '15%' }}>Data</th>
                    <th style={{ width: '25%' }}>Notes</th>
                    <th style={{ width: '2%' }}></th>
                </tr>
            </thead>
            <tbody>
                {daysOfWeek.map((day, index) => (
                    <>
                    <WorkoutRow
                        key={day.format('YYYY-MM-DD')}
                        day={day}
                        workouts={workouts?.filter((w) => w.date === day.format('YYYY-MM-DD')) || []}
                        workoutTypes={workoutTypes}
                        workoutSubtypes={workoutSubtypes}
                        updateWorkout={updateWorkout}
                        deleteWorkout={deleteWorkout}
                        createWorkout={createWorkout}
                    />
                    </>
                ))}

            </tbody>
        </Table>
        )}
        </>
    );
};

export default WorkoutTable;
